<template>
    <template v-if="isMobileOrTablet">
        <ODataLookup :dataObject="dsCostCodes" v-bind="$attrs, $props" ref="dropdownEl" :title="$t('Org Unit')">
            <template #target="{ target }">
                <slot name="target" :target="target">
                    <component v-if="is && !readOnly" :is="is" :ref="target" v-bind="$attrs" :value="textInputValue" readonly></component>
                    <component v-if="is && readOnly" :is="is" :ref="target" v-bind="$attrs" :value="textInputValue" readonly></component>
                    
                    <input v-else-if="textInput && !readOnly" :ref="target" v-bind="$attrs" :value="textInputValue" readonly>
                    <input v-else-if="textInput && readOnly" :ref="target" v-bind="$attrs" :value="textInputValue" readonly>
                    
                    <span v-else :ref="target" style="cursor:pointer;">
                        <slot name="costCode">{{ CostCode }}</slot>
                    </span>
                </slot>
            </template>
            <OColumn field="ID" width="60"/>
            <OColumn field="Name" width="300" :headerName="$t('Name')"/>
            <OColumn field="Title" width="300" :headerName="$t('Title')"/>
        </ODataLookup>
    </template>
    <template v-else>
        <DataLookupDropdown :dataLookupControl="lookupControl" @onopen="loadTree" ref="dropdownEl">
            <template #target="{ target }">
                <slot name="target" :target="target">
                    <component v-if="is" :is="is" :ref="target" v-bind="$attrs" :value="textInputValue"></component>
                    <input v-else-if="textInput" :ref="target" v-bind="$attrs" :value="textInputValue">
                    <span v-else :ref="target" style="cursor:pointer;">
                        <slot name="costCode">{{ CostCode }}</slot>
                    </span>
                </slot>
            </template>
            <ODataGrid :dataObject="dsCostCodesTree" :nodeData="treeConfig" hideGridMenu hideActionColumn hideMultiselectColumn :rowclickhandler="onCostCodeItemClicked"
                disableNavigation :multiselect="multiselect" ref="treeGridRef" :hideMenuItems="['columns','export','layouts']">
                <OColumn field="ID" width="80" hide></OColumn>
                <template #statusbar>
                    <OInfoItems :dataObject="dsCostCodesTree"></OInfoItems>
                    <div class="ms-auto">
                        <button class="btn btn-sm btn-link" style="white-space:nowrap;float:right" @click="handleClearInput">{{$t("Clear Input")}}</button>
                    </div>
                </template>                             
            </ODataGrid>
        </DataLookupDropdown>
    </template>
</template>

<script setup>
import { isMobileOrTablet } from "o365.GlobalState.ts";
import { InjectionKeys } from 'o365-utils';
import { ref, reactive, computed, defineProps, defineExpose, watch, inject, onMounted, onBeforeUnmount } from 'vue';
import { DataLookupDropdown, DataLookupControl } from 'o365-datalookup';
import { OInfoItems } from 'o365-datagrid';
import 'o365-nodedata';

const props = defineProps({
    bind: {
        type: Function,
        required: true
    },
    id: {
        type: String,
        required: false
    },
    whereClause: {
        type: String,
        required: false,        
    },
    textInput: {
        type: Boolean,
        default: false
    },
    value: null,
    modelValue: null,
    is: String,
    multiselect: {
        type: Boolean,
        default: false
    },
    readOnly: {
        type: Boolean,
        required: false,
        default: false
    },
    treeType: {
        type: String,
        default: 'costCode' // ['costCode', 'customCostCode']
    },
    receivedTreeFilter: {
        type: String,
        default: null
    }
});

// Make the whereClause prop reactive
const propWhereClause = computed(() => props.whereClause);

const CostCode = ref(null);
// TODO: Optimize for grids by injecting unique column id if lookup id isn't provided
const lookupId = props.id ?? crypto.randomUUID();
const dropdownEl = ref(null);
const treeGridRef = ref();

const gridEditorApi = inject(InjectionKeys.dataGridEditorCellControlKey, null);

const emit = defineEmits(['treeFilter']);

/** Value shown on the input when the selector is in textInput mode */
const textInputValue = computed(() => {
    return props.modelValue ?? props.value ?? CostCode.value
});

const costCodeDataConfig = {    
    viewName: 'aviw_Cost_CostCodes',
    loadRecents: true,
    distinctRows: true,
    maxRecords: 25,
    fields: [
        { name: "ID", type: "number" },
        { name: "Name", type: "string" },
        { name: "Title", type: "string" },
        { name: "Level", type: "number" },
        { name: "IdPath", type: "string", sortOrder: 2, sortDirection: "asc" },
        { name: "CostCodeType", type: "string", sortOrder: 1, sortDirection: "asc" },
        { name: "NamePath", type: "string" },
        { name: "Parent_ID", type: "string" },
    ]
};

const customCostCodeDataConfig = {    
    viewName: 'aviw_Cost_CostCodesCustomLkp',
    loadRecents: true,
    distinctRows: true,
    maxRecords: 25,
    fields: [
        { name: "ID", type: "number" },
        { name: "Name", type: "string" },
        { name: "Title", type: "string" },
        { name: "Level", type: "number" },
        { name: "IdPath", type: "string", sortOrder: 2, sortDirection: "asc" },
        { name: "CustomCostCodeType", type: "string", sortOrder: 1, sortDirection: "asc" },
        { name: "NamePath", type: "string" },
        { name: "Parent_ID", type: "string" },
    ]
};

// Refresh data source if any of the following refs are changed
watch([propWhereClause], () => {
        dsCostCodesTree.recordSource.whereClause = buildWhereClause();
        dsCostCodesTree.load();
        loadTree();
    }
);

const dsCostCodes = $getOrCreateDataObject({
    ... (props.treeType == 'costCode' ? costCodeDataConfig : customCostCodeDataConfig), // pick a config based on what type was passed as treeType
    id: 'o_dsCostCodes' + lookupId,
});

// Create a slightly modified copy of dsCostCodes so that we can use it in the tree
const dsCostCodesTree = $getOrCreateDataObject({    
    ... (props.treeType == 'costCode' ? costCodeDataConfig : customCostCodeDataConfig), // pick a config based on what type was passed as treeType
    id: 'o_dsCostCodes' + crypto.randomUUID(),
    selectFirstRowOnLoad: false,
    loadRecents: false,
    whereClause: buildWhereClause()
});

const treeConfig = {
    displayField: 'Name',
    getDisplay: row => row.Name ?? row.Title,
    column: {
        headerName: $t("Name"),
        boldDisplay: true
    }
};

function getColumns(){
    return [{ field: "ID", width: 80 }, { field: "Name", width: 300 }, { field: "Title", width: 300 }, { field: "CostCodeType", width: 200, hide: true }, { field: "Parent", width: 200, hide: true }];
}

function getHeight(){
    return window.innerHeight > 800 ? 500 : 300;
}

const lookupControl = reactive(new DataLookupControl({
    dataObject: dsCostCodes,
    multiselect: props.multiselect,
    bind: costCode => {
        if (props.bind) {
            props.bind(costCode)
        }
    },
    columns: getColumns(),
    height:  getHeight(),
    filterRow: true
}));

const onBeforeLoadCostCodes = dsCostCodes.on('BeforeLoad', (options) => {
    options.whereClause = buildWhereClause();
});

function buildWhereClause(opts = {}) {
    let baseWhereClause = propWhereClause.value ? "(" + propWhereClause.value + ")" : "";
    const clauses = [baseWhereClause];
    return clauses.filter(x => x).join(" AND ");
}

async function onCostCodeItemClicked(selected) {
    dropdownEl.value.dropdown.close();
    props.bind(selected.item);
}

onBeforeUnmount(() => {
    emit("treeFilter", dsCostCodesTree.recordSource.filterString );

    if (onBeforeLoadCostCodes) {
        onBeforeLoadCostCodes();
    }
});

function activateEditor() {
    dropdownEl.value?.dropdown?.open?.call();
}

onMounted(() => {
    if (gridEditorApi) {
        gridEditorApi.activate();
    }
});

function loadTree() {
    treeGridRef.value.dataGridControl.filterObject.applyInitFilter(props.receivedTreeFilter).then(() => {
        treeGridRef.value.dataGridControl.filterObject.apply();
    });
    dsCostCodesTree.nodeData.enable();
    dsCostCodesTree.nodeData.addConfiguration({ type: 'hierarchy', idField: 'ID', parentField: 'Parent_ID', requireParents: false });
    dsCostCodesTree.nodeData.init();
    dsCostCodesTree.hideToggleMultiselect = true;
}

defineExpose({ activateEditor });

function handleClearInput() {
    dropdownEl.value.dropdown.close();
    props.bind({
        ID: null,
        Name: null,
    });
}
</script>